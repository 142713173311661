
import { FaPaintBrush, FaFileVideo, FaSearchDollar } from 'react-icons/fa';
import { BsCodeSlash } from 'react-icons/bs';
import { MdPhotoCamera } from 'react-icons/md';
import { ImBullhorn } from 'react-icons/im';

const services = [
    {
        id: 1,
        icon: <BsCodeSlash />,
        title: 'Web Design',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 2,
        icon: <FaPaintBrush />,
        title: 'Graphic Design',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 3,
        icon: <MdPhotoCamera />,
        title: 'Photography',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        
    },
    {
        id: 4,
        icon: <ImBullhorn />,
        title: 'Digital Marketing',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 5,
        icon: <FaFileVideo />,
        title: 'Video Editing',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 6,
        icon: <FaSearchDollar />,
        title: 'SEO Marketing',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },

];

export default services;